<template>
  <v-card class="pt-8 pb-8 rounded-xl menu-item" elevation="1" :to="{name: itemMenu.to}">
    <v-card-title class="d-flex justify-center mb-7">
      <component class="pa-4" :is="itemMenu.icon"></component>
    </v-card-title>

    <v-card-text class="text-center primary--text font-weight-black text-h5">
      {{ itemMenu.name }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MenuItem",
  components: {
    IconPackages: () => import("./icons/IconPackages"),
    IconCustomers: () => import("./icons/IconCustomers"),
    IconChargers: () => import("./icons/IconCharges"),
    IconUsers: () => import("./icons/IconUsers"),
    IconMenuInternetMovil: () => import("./icons/IconMenuInternetMovil"),
    IconBill: () => import("./icons/IconCharges"),
    IconTemporalClients: () => import("./icons/IconTemporalClients")
  },
  props: {
    itemMenu: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item{
  height: 400px;
  transition: transform .2s ease-in-out;
  &:hover{
    transform: scale(1.05);
  }
}
</style>